import * as React from "react";
import { Form, Card, Row, Col, Tabs, Tab, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import TrialPercentErrorInfo from "./TrialPercentErrorInfo";

import * as Types from "../../lib/Types";
import {AppContext, initialState, reducer} from "./AppState";
import {formatDecimalToCurrencyString, formatDecimalToString} from "../../lib/Helpers";


interface IProps {
    title: string,
    trial: Types.TrialItem,
    displayIndex: number,
}

export const TrialInputs: React.FC<IProps> = (props: React.PropsWithChildren<any>): React.ReactElement => {

    const { state, dispatch } = React.useContext(AppContext);

    const handleUpdateTrial = ( trialAttName: string, trialAttValue: string | number ): void => {

        const newTrialData: Types.TrialItem = {
            ...props.trial,
            [trialAttName]: trialAttValue
        }

        dispatch({
            type: "update_trial",
            payload: newTrialData
        });
    };

    const handleDeleteTrial = ( uuid: string ): void => {
        console.log(uuid)
        dispatch({
            type: "delete_trial",
            payload: { uuid }
        });
    };

    return <div>

        <Card className={"shadow-sm"}>
            <Card.Header className="d-flex justify-content-between align-items-center">

                { props.title }

                <Button
                    variant="secondary"
                    onClick={(): void => handleDeleteTrial(props.trial.uuid)}
                >
                    Delete Trial
                </Button>

            </Card.Header>
            <Card.Body>

                <Form noValidate>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Location Name</Form.Label>
                        <Form.Control
                            type=""
                            placeholder="Location Name"
                            defaultValue={props.trial.location_name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                handleUpdateTrial('location_name', event.target.value);
                            }}
                        />
                        <Form.Text className="text-muted">
                            Enter Location Name
                        </Form.Text>
                    </Form.Group>

                </Form>
                <If condition={state.productOne && state.productTwo}>

                    <Tabs defaultActiveKey="product1" id="" className="mb-3">
                        <Tab eventKey="product1" title="Product 1">
                            <div>
                                <Alert variant={"primary"}>
                                    { state.productOne.product_name }
                                </Alert>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Faucet PSI (Optional)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Faucet PSI"
                                        defaultValue={ props.trial.product_one_faucet_psi }
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            handleUpdateTrial('product_one_faucet_psi', parseFloat(event.target.value));
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter Faucet PSI
                                    </Form.Text>
                                </Form.Group>

                                <Row>
                                    <Col>

                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Pre-Fill Weight of Bottle (lbs)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder=""
                                                defaultValue={ props.trial.product_one_pre_fill_weight }
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                    handleUpdateTrial('product_one_pre_fill_weight', parseFloat(event.target.value));
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Enter Pre-Fill Weight of Bottle (lbs)
                                            </Form.Text>
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Post-Fill Weight of Bottle (lbs)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder=""
                                                defaultValue={props.trial.product_one_post_fill_weight}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                    handleUpdateTrial('product_one_post_fill_weight', parseFloat(event.target.value));
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Enter Post-Fill Weight of Bottle (lbs)
                                            </Form.Text>
                                        </Form.Group>

                                    </Col>
                                </Row>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label># Gallons Dispensed</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        defaultValue={props.trial.product_one_num_gallons_dispensed}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            handleUpdateTrial('product_one_num_gallons_dispensed', parseFloat(event.target.value));
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter # Gallons Dispensed
                                    </Form.Text>
                                </Form.Group>

                                <hr/>

                                <Row>
                                    <Col>

                                        % Error From MFG Target:
                                        <br/>
                                        { formatDecimalToString(props.trial.product_one_percent_error_from_mfg_target, 0) }%

                                    </Col>
                                    <Col>

                                        Realized Cost/RTU Gallon:
                                        <br/>
                                        { formatDecimalToCurrencyString(props.trial.product_one_realized_cost_rtu_gallon) }

                                    </Col>
                                </Row>

                                <TrialPercentErrorInfo
                                    percent_error_value={props.trial.product_one_percent_error_from_mfg_target}
                                />

                            </div>
                        </Tab>

                        <Tab eventKey="product2" title="Product 2">

                            <div>

                                <Alert variant={"primary"}>
                                    { state.productTwo.product_name }
                                </Alert>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Faucet PSI (Optional)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Faucet PSI"
                                        defaultValue={ props.trial.product_two_faucet_psi }
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            handleUpdateTrial('product_two_faucet_psi', parseFloat(event.target.value));
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter Faucet PSI
                                    </Form.Text>
                                </Form.Group>

                                <Row>
                                    <Col>

                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Pre-Fill Weight of Bottle (lbs)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder=""
                                                defaultValue={ props.trial.product_two_pre_fill_weight }
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                    handleUpdateTrial('product_two_pre_fill_weight', parseFloat(event.target.value));
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Enter Pre-Fill Weight of Bottle (lbs)
                                            </Form.Text>
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Post-Fill Weight of Bottle (lbs)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder=""
                                                defaultValue={props.trial.product_two_post_fill_weight}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                    handleUpdateTrial('product_two_post_fill_weight', parseFloat(event.target.value));
                                                }}
                                            />
                                            <Form.Text className="text-muted">
                                                Enter Post-Fill Weight of Bottle (lbs)
                                            </Form.Text>
                                        </Form.Group>

                                    </Col>
                                </Row>

                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label># Gallons Dispensed</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        defaultValue={props.trial.product_two_num_gallons_dispensed}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                            handleUpdateTrial('product_two_num_gallons_dispensed', parseFloat(event.target.value));
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter # Gallons Dispensed
                                    </Form.Text>
                                </Form.Group>

                                <hr/>

                                <Row>
                                    <Col>

                                        % Error From MFG Target:
                                        <br/>
                                        { formatDecimalToString(props.trial.product_two_percent_error_from_mfg_target, 0) }%

                                    </Col>
                                    <Col>

                                        Realized Cost/RTU Gallon:
                                        <br/>
                                        { formatDecimalToCurrencyString(props.trial.product_two_realized_cost_rtu_gallon) }

                                    </Col>
                                </Row>

                                <TrialPercentErrorInfo
                                    percent_error_value={props.trial.product_two_percent_error_from_mfg_target}
                                />

                            </div>
                        </Tab>
                    </Tabs>

                </If>
            </Card.Body>
        </Card>

    </div>;
};