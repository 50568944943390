import * as Types from "./Types";

class Calcs {

    constructor() {
        return null;
    }

    trialResults = (input: Types.TrialResultsInput): Types.TrialResultsOutput => {

        // Product 1
        const D3: number = input.productOne.chemical_container_volume;
        const E3: number = input.productOne.rtu_active_ppm;
        const F3: number = input.productOne.flow_control_label_yield_gallons;
        const G3: number = input.productOne.ounces_per_gallon;
        const H3: number = input.productOne.containers_per_case;
        const I3: number = 128 / G3; // Label Dilution
        const J3: number = F3 / (D3 / 3.785); // Yield Dilution
        const K3: number = input.trial.product_one_faucet_psi; // Faucet PSI
        const L3: number = input.trial.product_one_pre_fill_weight; // Pre-fill Weight of Bottle (lbs)
        const M3: number = input.trial.product_one_post_fill_weight; // Post-fill Weight of Bottle (lbs)
        const N3: number = input.trial.product_one_num_gallons_dispensed; // # Gallons Dispensed
        const O3: number = L3 - M3; // Chemical Used (lbs)
        const P3: number = (N3 * 8.345 - O3) / O3; // Measured Dilution (1:x)
        const Q3: number = I3 / P3 * E3; // Measured PPM
        const R3: number = -1 / (J3 / (P3 - J3)) * 100; // % Error From MFG Target
        const S3: number = -1 / (I3 / (P3 - I3)) * 100; // % Error from EPA Target
        const T3: number = input.productOneCaseCost / (((D3 * 0.264172) * H3) * (P3 + 1)); // Realized Cost / RTU Gallon

        // Product 2
        const D4: number = input.productTwo.chemical_container_volume;
        const E4: number = input.productTwo.rtu_active_ppm;
        const F4: number = input.productTwo.flow_control_label_yield_gallons;
        const G4: number = input.productTwo.ounces_per_gallon;
        const H4: number = input.productTwo.containers_per_case;
        const I4: number = 128 / G4; // Label Dilution
        const J4: number = F4 / (D4 / 3.785); // Yield Dilution
        const K4: number = input.trial.product_two_faucet_psi; // Faucet PSI
        const L4: number = input.trial.product_two_pre_fill_weight; // Pre-fill Weight of Bottle (lbs)
        const M4: number = input.trial.product_two_post_fill_weight; // Post-fill Weight of Bottle (lbs)
        const N4: number = input.trial.product_two_num_gallons_dispensed; // # Gallons Dispensed
        const O4: number = L4 - M4; // Chemical Used (lbs)
        const P4: number = (N4 * 8.345 - O4) / O4; // Measured Dilution (1:x)
        const Q4: number = I4 / P4 * E4; // Measured PPM
        const R4: number = -1 / (J4 / (P4 - J4)) * 100; // % Error From MFG Target
        const S4: number = -1 / (I4 / (P4 - I4)) * 100; // % Error from EPA Target
        const T4: number = input.productTwoCaseCost / (((D4 * 0.264172) * H4) * (P4 + 1)); // Realized Cost / RTU Gallon

        const result: Types.TrialResultsOutput = {
            trial: input.trial,
            productOnePercentError: R3,
            productOneRealizedCostRtuGallon: T3,
            productTwoPercentError: R4,
            productTwoRealizedCostRtuGallon: T4
        };

        return result;
    };

    allTrialsResults = (input: Types.AllTrialsResultsInputs): Types.AllTrialsResultsOutputs => {

        const numberOfTrials: number = input.trials.length;

        // Get set of all trial results
        const trialResults: Types.TrialResultsOutput[] = _.map(input.trials, (trial: Types.TrialItem): Types.TrialResultsOutput => {

            const tmp: Types.TrialResultsInput = {
                "trial": trial,
                "productOne": input.productOne,
                "productOneCaseCost": input.productOneCaseCost,
                "productTwo": input.productTwo,
                "productTwoCaseCost": input.productTwoCaseCost
            };

            return this.trialResults(tmp);
        });

        // Sum / Average Cost Product One
        const sumCostProductOne: number = _.reduce(trialResults, (sum: number, item: Types.TrialResultsOutput): number => {
            return sum + item.productOneRealizedCostRtuGallon;
        }, 0);

        const averageCostProductOne: number = sumCostProductOne / numberOfTrials;

        // Sum / Average Cost Product Two
        const sumCostProductTwo: number = _.reduce(trialResults, (sum: number, item: Types.TrialResultsOutput): number => {
            return sum + item.productTwoRealizedCostRtuGallon;
        }, 0);

        const averageCostProductTwo: number = sumCostProductTwo / numberOfTrials;

        // Sum / Average Percent Error Product One
        const sumPercentErrorProductOne: number = _.reduce(trialResults, (sum: number, item: Types.TrialResultsOutput): number => {
            return sum + item.productOnePercentError;
        }, 0);

        const averagePercentErrorProductOne: number = sumPercentErrorProductOne / numberOfTrials;

        // Sum / Average Percent Error Product Two
        const sumPercentErrorProductTwo: number = _.reduce(trialResults, (sum: number, item: Types.TrialResultsOutput): number => {
            return sum + item.productTwoPercentError;
        }, 0);

        const averagePercentErrorProductTwo: number = sumPercentErrorProductTwo / numberOfTrials;

        // Bottom Lines Savings / Accuracy
        const potentialSavings: number = (averageCostProductOne - averageCostProductTwo) / averageCostProductOne;
        const potentialIncreasedAccuracy: number = (averagePercentErrorProductOne - averagePercentErrorProductTwo) / averagePercentErrorProductOne * 100;

        const result: Types.AllTrialsResultsOutputs = {
            "trials": input.trials,
            "productOne": input.productOne,
            "productTwo": input.productTwo,
            "averageCostProductOne": averageCostProductOne,
            "averageCostProductTwo": averageCostProductTwo,
            "potentialSavings": potentialSavings,
            "averagePercentErrorProductOne": averagePercentErrorProductOne,
            "averagePercentErrorProductTwo": averagePercentErrorProductTwo,
            "potentialIncreasedAccuracy": potentialIncreasedAccuracy
        };

        return result;
    };

}

export default new Calcs();