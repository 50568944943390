import * as React from "react";
import C3Chart from "react-c3js";
import {ChartDataType, NoPropsType} from "../../../lib/Types";
import {AppContext} from "../AppState";

import * as Helpers from "../../../lib/Helpers"

export const PercentErrorChart: React.FC<NoPropsType> = (props: NoPropsType): React.ReactElement => {

    const {state} = React.useContext(AppContext);
    const chartRef: React.MutableRefObject<C3Chart> = React.useRef()
    const chartData: ChartDataType = Helpers.getPercentErrorChartData(state);

    // On Chart Data Change, Reload Chart Data using C3 API: https://c3js.org/samples/data_load.html
    // Cannot just pass new data directly to rect-c3js, causes issues: https://github.com/bcbcarl/react-c3js/issues/15
    // Solution Reference: https://c3js.org/reference.html#api-load
    React.useEffect((): void => {
        chartRef.current.chart.load({
            ...chartData,
            unload: true,
        })
    }, [chartData])

    // console.log(chartData);

    return <C3ChartOnMount
        chartRef={chartRef}
        chartData={chartData}
    />
}

class C3ChartOnMount extends React.Component<{ chartRef: React.MutableRefObject<C3Chart>, chartData: ChartDataType }> {

    // Only render OnMount
    shouldComponentUpdate = (): boolean => false

    render(): JSX.Element {

        return <C3Chart
            ref={this.props.chartRef}
            data={this.props.chartData}
            axis={{
                x: {
                    type: 'category',
                    categories: _.times(10, (n) => `Trial ${n + 1}`)
                },
                y: {
                    label: '% Error'
                }
            }}
            grid={{
                x: {
                    show: false
                },
                y: {
                    show: true
                }
            }}
        />
    }

}
