import * as Types from "./Types";
import Calcs from "./Calcs";
import Constants from "./Constants";
import {AllTrialsResultsData, ProductItem, TrialItem} from "./Types";

export const productForKey = (key: string): Types.ProductItem => {

    const result: Types.ProductItem = _.find(Constants.products.productList, (p: Types.ProductItem): boolean => {
        return p.number === key;
    });

    return result;
};

export const stateWithNewCalcs = (appState: Types.AppStateType): Types.AppStateType => {
    return {
        ...appState,
        trials: processAllTrialsData(appState)
    }
};

export const processAllTrialsData = (appState: Types.AppStateType): Types.TrialItem[] => {
    return _.map(appState.trials, (trial: Types.TrialItem): Types.TrialItem => {
        return processTrialData(appState, trial);
    });
};

export const processTrialData = (appState: Types.AppStateType, trial: Types.TrialItem): Types.TrialItem => {

    if (appState.productOne !== null && appState.productTwo !== null &&
        appState.productOneCaseCost !== null  && appState.productTwoCaseCost !== null) {

        const tmpTrialResult: any = Calcs.trialResults({
            "trial": trial,
            "productOne": appState.productOne,
            "productOneCaseCost": appState.productOneCaseCost,
            "productTwo": appState.productTwo,
            "productTwoCaseCost": appState.productTwoCaseCost
        });

        // Add values to trial results that will returned to app state
        trial.product_one_percent_error_from_mfg_target = tmpTrialResult.productOnePercentError;
        trial.product_one_realized_cost_rtu_gallon = tmpTrialResult.productOneRealizedCostRtuGallon;
        trial.product_two_percent_error_from_mfg_target = tmpTrialResult.productTwoPercentError;
        trial.product_two_realized_cost_rtu_gallon = tmpTrialResult.productTwoRealizedCostRtuGallon;

    }

    return trial;
};

export const allTrialsResultsData = (appState: Types.AppStateType): Types.AllTrialsResultsOutputs => {

    return Calcs.allTrialsResults({
        trials: appState.trials,
        productOne: appState.productOne,
        productOneCaseCost: appState.productOneCaseCost,
        productTwo: appState.productTwo,
        productTwoCaseCost: appState.productTwoCaseCost
    });
}

export const roundDecimal = (result: number, precision: number): number => {
    return Math.round(result * 10**precision ) / 10**precision
}

export const formatDecimalToString = (result: number, precision: number): string => {

    if (Number.isNaN(result)) {
        return 'NaN'
    }

    return roundDecimal(result, precision).toString()
}

export const formatDecimalToCurrencyString = (result: number): string => {

    if (Number.isNaN(result)) {
        return 'NaN'
    }

    return `$${formatDecimalToString(result, 2)}`
}

export const getChartInputs = (state: Types.AppStateType): Types.ChartDataType => {

    const productOneLabel: string = state.productOne.number
    const productTwoLabel: string = state.productTwo.number
    const productOneErrFromTargetLabel: string = `${productOneLabel} % Error from EPA Target`
    const productTwoErrFromTargetLabel: string = `${productTwoLabel} % Error from EPA Target`
    const productOneRealizedCostLabel: string = `${productOneLabel} Realized Cost/RTU Gallon`
    const productTwoRealizedCostLabel: string = `${productTwoLabel} Realized Cost/RTU Gallon`

    // get an array of attributes for plotting
    const attributeArrayFromTrials = (attLabel: string): number[] => _.map(state.trials,
        (trial: any): number => roundDecimal(trial[attLabel], 2) )

    const percentErrsProductOne: number[] = attributeArrayFromTrials('product_one_percent_error_from_mfg_target')
    const percentErrsProductTwo: number[] = attributeArrayFromTrials('product_two_percent_error_from_mfg_target')
    const realizedCostsProductOne: number[] = attributeArrayFromTrials('product_one_realized_cost_rtu_gallon')
    const realizedCostsProductTwo: number[] = attributeArrayFromTrials('product_two_realized_cost_rtu_gallon')

    return  {
        columns: [
            [ productOneErrFromTargetLabel, ...percentErrsProductOne],
            [ productTwoErrFromTargetLabel, ...percentErrsProductTwo],
            [ productOneRealizedCostLabel, ...realizedCostsProductOne],
            [ productTwoRealizedCostLabel, ...realizedCostsProductTwo]
        ],
        types: {
            [ productOneErrFromTargetLabel ]: 'bar',
            [ productTwoErrFromTargetLabel ]: 'bar',
            [ productOneRealizedCostLabel ]: 'line',
            [ productTwoRealizedCostLabel ]: 'line'
        },
        axes: {
            [ productOneErrFromTargetLabel ]: 'y',
            [ productTwoErrFromTargetLabel ]: 'y',
            [ productOneRealizedCostLabel ]: 'y2',
            [ productTwoRealizedCostLabel ]: 'y2'
        },
        colors: {
            [ productOneErrFromTargetLabel ]: '#ced4da',
            [ productTwoErrFromTargetLabel ]: '#868e96',
            [ productOneRealizedCostLabel ]: '#FF0000',
            [ productTwoRealizedCostLabel ]: '#000000'
        },
    };
}

export const getPercentErrorChartData = (state: Types.AppStateType): Types.ChartDataType => {

    const productOneLabel: string = state.productOne.number
    const productTwoLabel: string = state.productTwo.number
    const productOneErrFromTargetLabel: string = `${productOneLabel}`
    const productTwoErrFromTargetLabel: string = `${productTwoLabel}`

    // get an array of attributes for plotting
    const attributeArrayFromTrials = (attLabel: string): number[] => _.map(state.trials,
        (trial: any): number => roundDecimal(trial[attLabel], 2) )

    const percentErrsProductOne: number[] = attributeArrayFromTrials('product_one_percent_error_from_mfg_target')
    const percentErrsProductTwo: number[] = attributeArrayFromTrials('product_two_percent_error_from_mfg_target')

    return  {
        columns: [
            [ productOneErrFromTargetLabel, ...percentErrsProductOne],
            [ productTwoErrFromTargetLabel, ...percentErrsProductTwo]
        ],
        types: {
            [ productOneErrFromTargetLabel ]: 'bar',
            [ productTwoErrFromTargetLabel ]: 'bar'
        },
        axes: {
            [ productOneErrFromTargetLabel ]: 'y',
            [ productTwoErrFromTargetLabel ]: 'y'
        },
        labels: {
            format: (v, id, i, j) => {
                return `${ v } %`
            }
        },
        colors: {
            [ productOneErrFromTargetLabel ]: '#20c997',
            [ productTwoErrFromTargetLabel ]: '#0b7285'
        }
    };
}

export const getPriceFluctuationChartData = (state: Types.AppStateType): Types.ChartDataType => {

    const productOneLabel: string = state.productOne.number
    const productTwoLabel: string = state.productTwo.number
    const productOneRealizedCostLabel: string = `${productOneLabel}`
    const productTwoRealizedCostLabel: string = `${productTwoLabel}`

    // get an array of attributes for plotting
    const attributeArrayFromTrials = (attLabel: string): number[] => _.map(state.trials,
        (trial: any): number => roundDecimal(trial[attLabel], 2) )

    const realizedCostsProductOne: number[] = attributeArrayFromTrials('product_one_realized_cost_rtu_gallon')
    const realizedCostsProductTwo: number[] = attributeArrayFromTrials('product_two_realized_cost_rtu_gallon')

    return  {
        columns: [
            [ productOneRealizedCostLabel, ...realizedCostsProductOne],
            [ productTwoRealizedCostLabel, ...realizedCostsProductTwo]
        ],
        types: {
            [ productOneRealizedCostLabel ]: 'line',
            [ productTwoRealizedCostLabel ]: 'line'
        },
        axes: {
            [ productOneRealizedCostLabel ]: 'y',
            [ productTwoRealizedCostLabel ]: 'y'
        },
        labels: {
            format: (v, id, i, j) => {
                return `$ ${ v }`
            }
        },
        colors: {
            [ productOneRealizedCostLabel ]: '#20c997',
            [ productTwoRealizedCostLabel ]: '#0b7285'
        }
    };
}