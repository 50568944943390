import * as React from "react";
import * as Types from "../../lib/Types";
import * as Helpers from "../../lib/Helpers";

import { v4 as uuidv4 } from "uuid";
import {stateWithNewCalcs} from "../../lib/Helpers";

const initalStateDebug: Types.AppStateType = Helpers.stateWithNewCalcs({

    productOne: Helpers.productForKey("Virex® II 256"),
    productTwo: Helpers.productForKey("3M #25"),
    productOneCaseCost: 34.85,
    productTwoCaseCost: 50.75,
    trials: [
        {
            uuid: uuidv4(),
            location_name: "Location 1",
            product_one_faucet_psi: null,
            product_two_faucet_psi: null,
            product_one_pre_fill_weight: 4.9956,
            product_two_pre_fill_weight: 4.282,
            product_one_post_fill_weight: 4.7904,
            product_two_post_fill_weight: 4.1394,
            product_one_num_gallons_dispensed: 5,
            product_two_num_gallons_dispensed: 5,
        },
        {
            uuid: uuidv4(),
            location_name: "Location 2",
            product_one_faucet_psi: null,
            product_two_faucet_psi: null,
            product_one_pre_fill_weight: 5.7918,
            product_two_pre_fill_weight: 4.1394,
            product_one_post_fill_weight: 5.5772,
            product_two_post_fill_weight: 3.991,
            product_one_num_gallons_dispensed: 5,
            product_two_num_gallons_dispensed: 5,
        },
        {
            uuid: uuidv4(),
            location_name: "Location 3",
            product_one_faucet_psi: null,
            product_two_faucet_psi: null,
            product_one_pre_fill_weight: 5.7918,
            product_two_pre_fill_weight: 4.1394,
            product_one_post_fill_weight: 5.5772,
            product_two_post_fill_weight: 3.991,
            product_one_num_gallons_dispensed: 5,
            product_two_num_gallons_dispensed: 5,
        },
        {
            uuid: uuidv4(),
            location_name: "Location 4",
            product_one_faucet_psi: null,
            product_two_faucet_psi: null,
            product_one_pre_fill_weight: 4.4388,
            product_two_pre_fill_weight: 3.8474,
            product_one_post_fill_weight: 4.2518,
            product_two_post_fill_weight: 3.7004,
            product_one_num_gallons_dispensed: 5,
            product_two_num_gallons_dispensed: 5,
        },
        {
            uuid: uuidv4(),
            location_name: "Location 5",
            product_one_faucet_psi: null,
            product_two_faucet_psi: null,
            product_one_pre_fill_weight: 0.75,
            product_two_pre_fill_weight: 3.6988,
            product_one_post_fill_weight: 0.541,
            product_two_post_fill_weight: 3.5578,
            product_one_num_gallons_dispensed: 5,
            product_two_num_gallons_dispensed: 5,
        },
        {
            uuid: uuidv4(),
            location_name: "Location 6",
            product_one_faucet_psi: null,
            product_two_faucet_psi: null,
            product_one_pre_fill_weight: 3.2224,
            product_two_pre_fill_weight: 3.2622,
            product_one_post_fill_weight: 3.0256,
            product_two_post_fill_weight: 3.1128,
            product_one_num_gallons_dispensed: 5,
            product_two_num_gallons_dispensed: 5
        }
    ]
});

// run calcs on debug state to get trial outputs
// initalStateDebug.trials = Helpers.processAllTrialsData(initalStateDebug, initalStateDebug.trials)

const getDefaultTrial = (trialNumber: number): Types.TrialItem => ({
    uuid: uuidv4(),
    location_name: `Location ${ trialNumber }`,
    product_one_faucet_psi: null,
    product_two_faucet_psi: null,
    product_one_pre_fill_weight: 2,
    product_two_pre_fill_weight: 2,
    product_one_post_fill_weight: 1,
    product_two_post_fill_weight: 1,
    product_one_num_gallons_dispensed: 1,
    product_two_num_gallons_dispensed: 1,
})

const initialState: Types.AppStateType = Helpers.stateWithNewCalcs({
    productOne: null,
    productTwo: null,
    productOneCaseCost: 0.0,
    productTwoCaseCost: 0.0,
    trials: [
        getDefaultTrial(1),
    ]
});

const reducer = (state: Types.AppStateType, action: Types.AppStateAction): Types.AppStateType => {
    switch (action.type) {
        case "change_product_one":
            return Helpers.stateWithNewCalcs({
                ...state,
                productOne: action.payload
            })
        case "change_product_two":
            return Helpers.stateWithNewCalcs({
                ...state,
                productTwo: action.payload,
            })
        case "change_product_one_case_cost":
            return Helpers.stateWithNewCalcs({
                ...state,
                productOneCaseCost: action.payload,
            })
        case "change_product_two_case_cost":
            return Helpers.stateWithNewCalcs({
                ...state,
                productTwoCaseCost: action.payload,
            })
        case "add_trial":

            const trialNumber: number = state.trials.length + 1;
            const tmp: Types.TrialItem[] = [...state.trials, getDefaultTrial(trialNumber)];
            return Helpers.stateWithNewCalcs({
                ...state,
                trials: tmp
            })

        case "update_trial":

            const index: number = _.findIndex(state.trials, { uuid: action.payload.uuid });

            // Replace item at index using native splice
            state.trials.splice(index, 1, action.payload);

            return Helpers.stateWithNewCalcs(state)

        case "delete_trial":

            const deleteIndex: number = _.findIndex(state.trials, { uuid: action.payload.uuid });

            state.trials.splice(deleteIndex, 1);

            return Helpers.stateWithNewCalcs(state)

        default:
            throw new Error();
    }
};

const AppContext: React.Context<Types.ContextType | null> = React.createContext(null);

export { reducer, initialState, initalStateDebug, AppContext };