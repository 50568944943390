import React from "react";

import * as Types from "../../lib/Types";

const TrialPercentErrorInfo:React.FC<Types.PercentErrorValue> = (props: Types.PercentErrorValue): React.ReactElement => {

    return (
        <div>

            <Choose>
                <When condition={props.percent_error_value <= 0.1 && props.percent_error_value >= -0.1 }>

                    <div className="alert alert-info mt-4">
                        System is diluting to label specification
                    </div>

                </When>
                <When condition={props.percent_error_value > 0.1}>

                    <div className="alert alert-above mt-4">

                        <h4>ABOVE</h4>
                        <p>
                            Under Dilution (not enough water, too much chemical)
                        </p>
                        <ol>
                            <li>
                                Potential for Increased Exposure and Facility Costs
                            </li>
                            <ul>
                                <li>
                                    Potential for over budgeting/increased cost due to inconsistent chemical dispensing
                                </li>
                                <li>
                                    Potential for harmful exposure to unintended concentrations of chemicals
                                </li>
                                <li>
                                    Potential for surface degradation caused by overly acidic or basic chemical mixes
                                </li>
                            </ul>
                            <li>
                                Potential for Excess Chemical Residue
                            </li>
                            <ul>
                                <li>
                                    Potential for higher frequency of sticky or increased residue/buildup on surfaces
                                </li>
                                <li>
                                    Potential for the need to re-clean or remove chemical residue from surfaces
                                </li>
                                <li>
                                    Potential for additional labor to help in re-cleaning or surface replacement efforts
                                </li>
                            </ul>
                        </ol>

                    </div>

                </When>
                <When condition={props.percent_error_value < -0.1}>

                    <div className="alert alert-below mt-4">

                        <h4>BELOW</h4>
                        <p>
                            Over Dilution (too much water, not enough chemical)
                        </p>
                        <ol>
                            <li>
                                Potential for an Ineffective Disinfectant
                            </li>
                            <ul>
                                <li>
                                    Potential for decreased efficacy (not enough chemical, thus chemical doesn’t do what it is intended to do)
                                </li>
                                <li>
                                    Potential for non-compliance with the US EPA label as it is not the correct dilution
                                </li>
                                <li>
                                    Potential risk for facility
                                </li>
                            </ul>
                            <li>
                                Potential for Ineffective Cleaning Ability
                            </li>
                            <ul>
                                <li>
                                    Potential for re-cleaning of surfaces as you are not removing soils
                                </li>
                                <li>
                                    Potential for increased labor to help with re-cleaning efforts
                                </li>
                                <li>
                                    Potential for a not aesthetically clean facility
                                </li>
                            </ul>
                        </ol>

                    </div>

                </When>
            </Choose>

        </div>
    );
}

export default TrialPercentErrorInfo;