import * as Types from "./Types";

class Constants {

    products: Types.ProductItemList;
    applicationStrings: {};

    constructor() {

        this.products = {
            productList: [
                {
                    "number": "3M #4",
                    "product_name": "3M™ Bathroom Disinfectant Cleaner Concentrate (No. 4)",
                    "epa_reg_no": "6836-309",
                    "color": "Green",
                    "dilution": "1 to 51",
                    "ounces_per_gallon": 2.5,
                    "rtu_active_ppm": 1000,
                    "rtu_ph": 3,
                    "flow_control_label_yield_gallons": 26,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #5",
                    "product_name": "3M™ Quat Disinfectant Cleaner Concentrate (No. 5)",
                    "epa_reg_no": "6836-78",
                    "color": "Light Green",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 9.6,
                    "flow_control_label_yield_gallons": 107,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #15",
                    "product_name": "3M™ Non-Acid Bathroom Disinfectant Cleaner Concentrate (No. 15)",
                    "epa_reg_no": "1839-166",
                    "color": "Light Green",
                    "dilution": "1 to 128",
                    "ounces_per_gallon": 1,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 7,
                    "flow_control_label_yield_gallons": 61,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #16",
                    "product_name": "3M™ Sanitizer Concentrate (No. 16)",
                    "epa_reg_no": "47371-147",
                    "color": "Cloudy White",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 150,
                    "rtu_ph": 6.1,
                    "flow_control_label_yield_gallons": 75,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #23",
                    "product_name": "3M™ Neutral Quat Disinfectant Cleaner Concentrate (No. 23)",
                    "epa_reg_no": "47371-129",
                    "color": "Light Green",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 660,
                    "rtu_ph": 7.5,
                    "flow_control_label_yield_gallons": 128,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #25",
                    "product_name": "3M™ HB Quat Disinfectant Cleaner Concentrate (No. 25)",
                    "epa_reg_no": "61178-5",
                    "color": "Light Yellow",
                    "dilution": "1 to 365",
                    "ounces_per_gallon": 0.35,
                    "rtu_active_ppm": 708,
                    "rtu_ph": 9,
                    "flow_control_label_yield_gallons": 155,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #40",
                    "product_name": "3M™ Quat RCT Disinfectant Cleaner Concentrate (No. 40)",
                    "epa_reg_no": "6836-349",
                    "color": "Light Yellow",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 860,
                    "rtu_ph": 9.6,
                    "flow_control_label_yield_gallons": 116,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #41",
                    "product_name": "3M™ MBS Disinfectant Cleaner Concentrate Fresh Scent (No. 41)",
                    "epa_reg_no": "6836-361",
                    "color": "Green or colorless",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 1049,
                    "rtu_ph": 9,
                    "flow_control_label_yield_gallons": 110,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                {
                    "number": "3M #42",
                    "product_name": "3M™ MBS Disinfectant Cleaner Concentrate (No. 42)",
                    "epa_reg_no": "6836-361",
                    "color": "Green or colorless",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 1049,
                    "rtu_ph": 9,
                    "flow_control_label_yield_gallons": 110,
                    "chemical_container_volume": 1.9,
                    "containers_per_case": 4,
                    "company": "3M"
                },
                // {
                //     "number": "3M #3",
                //     "product_name": "3M Neutral Cleaner Concentrate (No. 3A)",
                //     "epa_reg_no": "N/A",
                //     "color": "Yellow",
                //     "dilution": "1 to 343",
                //     "ounces_per_gallon": 0.373,
                //     "rtu_active_ppm": "N/A",
                //     "rtu_ph": 7,
                //     "flow_control_label_yield_gallons": 172,
                //     "chemical_container_volume": 1.9,
                //     "containers_per_case": 4,
                //     "company": "3M"
                // },
                // {
                //     "number": "3M #17",
                //     "product_name": "3M Glass Cleaner and Protector Concentrate (No. 17A)",
                //     "epa_reg_no": "N/A",
                //     "color": "Blue",
                //     "dilution": "1 to 85",
                //     "ounces_per_gallon": 1.51,
                //     "rtu_active_ppm": "N/A",
                //     "rtu_ph": 8.5,
                //     "flow_control_label_yield_gallons": 43,
                //     "chemical_container_volume": 1.9,
                //     "containers_per_case": 4,
                //     "company": "3M"
                // },
                // {
                //     "number": "3M #24",
                //     "product_name": "3M 3-in-1 Floor Cleaner Concentrate (No. 24A)",
                //     "epa_reg_no": "N/A",
                //     "color": "Blue",
                //     "dilution": "1 to 256",
                //     "ounces_per_gallon": 0.5,
                //     "rtu_active_ppm": "N/A",
                //     "rtu_ph": 8,
                //     "flow_control_label_yield_gallons": 132,
                //     "chemical_container_volume": 1.9,
                //     "containers_per_case": 4,
                //     "company": "3M"
                // },
                {
                    "number": "Virex® II 256",
                    "product_name": "Diversey Virex®  II 256--J-Fill",
                    "epa_reg_no": "70627-24",
                    "color": "Blue ",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 660,
                    "rtu_ph": 8.8,
                    "flow_control_label_yield_gallons": 170,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Crew® NA SC",
                    "product_name": "Diversey Crew ® NA SC Non-Acid Bowl Bathroom Disinfectant Cleaner--J-Fill",
                    "epa_reg_no": "6836-73",
                    "color": "Blue",
                    "dilution": "1 to 128",
                    "ounces_per_gallon": 1,
                    "rtu_active_ppm": 297,
                    "rtu_ph": 10,
                    "flow_control_label_yield_gallons": 85,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Wide Range®",
                    "product_name": "Diversey Wide Range® II Disinfectant Cleaner--J-Fill",
                    "epa_reg_no": "6836-75",
                    "color": "Green",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 859,
                    "rtu_ph": 9.7,
                    "flow_control_label_yield_gallons": 43,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "J-512™",
                    "product_name": "Diversey J-512™ Sanitizer--J-Fill",
                    "epa_reg_no": "70627-63",
                    "color": "Red",
                    "dilution": "1 to 512",
                    "ounces_per_gallon": 0.25,
                    "rtu_active_ppm": 195,
                    "rtu_ph": 7,
                    "flow_control_label_yield_gallons": 339,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Virex® Plus (1:128)",
                    "product_name": "Diversey Virex® Plus One- Step Disinfectant Cleaner &Deodorant--J-Fill (1:128)",
                    "epa_reg_no": "6836-349",
                    "color": "Blue",
                    "dilution": "1 to 128",
                    "ounces_per_gallon": 1,
                    "rtu_active_ppm": 1737,
                    "rtu_ph": 9.6,
                    "flow_control_label_yield_gallons": 85,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Virex® Plus (1: 256)",
                    "product_name": "Diversey Virex® Plus One- Step Disinfectant Cleaner &Deodorant--J-Fill (1:256)",
                    "epa_reg_no": "6836-349",
                    "color": "Blue",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 860,
                    "rtu_ph": 9.6,
                    "flow_control_label_yield_gallons": 170,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Oxivir® Five 16 (1:16 dilution)",
                    "product_name": "Diversey Oxivir® Five 16 Concentrate--J-Fill",
                    "epa_reg_no": "70627-58",
                    "color": "Colorless",
                    "dilution": "1 to 16",
                    "ounces_per_gallon": 8,
                    "rtu_active_ppm": 2656,
                    "rtu_ph": 1.9,
                    "flow_control_label_yield_gallons": 10.5668875,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Oxivir® Five 16 (1:64 dilution)",
                    "product_name": "Diversey Oxivir® Five 16 Concentrate--J-Fill",
                    "epa_reg_no": "70627-58",
                    "color": "Colorless",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 664,
                    "rtu_ph": 1.9,
                    "flow_control_label_yield_gallons": 42.26755,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Spartan NABC®",
                    "product_name": "Spartan NABC® Concentrate (#1) ",
                    "epa_reg_no": "5741-20",
                    "color": "Blue",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 703,
                    "rtu_ph": 5,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan hdqC®",
                    "product_name": "Spartan hdqC® ",
                    "epa_reg_no": "1839-169",
                    "color": "Red",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 6,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan Neutral Disinf.",
                    "product_name": "Spartan Neutral Disinfectant Cleaner (#103)",
                    "epa_reg_no": "1839-170",
                    "color": "Green",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 7,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan BNC-15 ",
                    "product_name": "Spartan BNC-15 ",
                    "epa_reg_no": "6836-348",
                    "color": "Red",
                    "dilution": "1 to 128",
                    "ounces_per_gallon": 1,
                    "rtu_active_ppm": 868,
                    "rtu_ph": 12,
                    "flow_control_label_yield_gallons": 67.628,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan Clean by 4D",
                    "product_name": "Spartan Clean by 4D",
                    "epa_reg_no": "5741-30",
                    "color": "Blue",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 938,
                    "rtu_ph": 4,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan Halt",
                    "product_name": "Spartan Halt",
                    "epa_reg_no": "10324-93",
                    "color": "Orange",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 703,
                    "rtu_ph": 11,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan Metaquat",
                    "product_name": "Spartan Metaquat",
                    "epa_reg_no": "5741-12",
                    "color": "Red",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 547,
                    "rtu_ph": 11.8,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan Super HDQL-10",
                    "product_name": "Spartan Super HDQL-10",
                    "epa_reg_no": "1839-167",
                    "color": "Green",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 7,
                    "flow_control_label_yield_gallons": 135.256,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Spartan X-EFFECT",
                    "product_name": "Spartan X-EFFECT",
                    "epa_reg_no": "5741-20",
                    "color": "Purple",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 703,
                    "rtu_ph": 5,
                    "flow_control_label_yield_gallons": 33.814,
                    "chemical_container_volume": 2,
                    "containers_per_case": 4,
                    "company": "Spartan"
                },
                {
                    "number": "Ecolab Facilipro Peroxide MC Disinfectant",
                    "product_name": "Ecolab Facilipro 54 Peroxide Multi Surface Cleaner and Disinfectant",
                    "epa_reg_no": "1677-238",
                    "color": "Yellow",
                    "dilution": "1 to 32",
                    "ounces_per_gallon": 4,
                    "rtu_active_ppm": 2500,
                    "rtu_ph": 2,
                    "flow_control_label_yield_gallons": 10.98,
                    "chemical_container_volume": 1.3,
                    "containers_per_case": 2,
                    "company": "Ecolab"
                },
                {
                    "number": "Ecolab Facilipro A-456 II",
                    "product_name": "Ecolab Facilipro A-456 II Disinfectant Cleaner",
                    "epa_reg_no": "6836-78",
                    "color": "Red",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 10.5,
                    "flow_control_label_yield_gallons": 88,
                    "chemical_container_volume": 1.3,
                    "containers_per_case": 2,
                    "company": "Ecolab"
                },
                {
                    "number": "Ecolab Facilipro Neutral Disinfectant Cleaner",
                    "product_name": "Ecolab Facilipro Neutral Disinfectant Cleaner",
                    "epa_reg_no": "47371-129",
                    "color": "Red",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 660,
                    "rtu_ph": 7.5,
                    "flow_control_label_yield_gallons": 88,
                    "chemical_container_volume": 1.3,
                    "containers_per_case": 2,
                    "company": "Ecolab"
                },
                {
                    "number": "Hillyard Non-Acid Restroom Disinfectant Cleaner--#19",
                    "product_name": "Hillyard Non-Acid Restroom Disinfectant Cleaner",
                    "epa_reg_no": "1839-169",
                    "color": "Blue",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 6,
                    "flow_control_label_yield_gallons": 42,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 4,
                    "company": "Hillyard"
                },
                {
                    "number": "Hillyard QT Plus",
                    "product_name": "Hillyard Q.T. Plus",
                    "epa_reg_no": "6836-77",
                    "color": "Yellow",
                    "dilution": "1 to 128",
                    "ounces_per_gallon": 1,
                    "rtu_active_ppm": 848,
                    "rtu_ph": 12,
                    "flow_control_label_yield_gallons": 84.5,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 4,
                    "company": "Hillyard"
                },
                {
                    "number": "Hillyard Suprox-D",
                    "product_name": "Hillyard Suprox-D",
                    "epa_reg_no": "1839-224",
                    "color": "Clear",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 900,
                    "rtu_ph": 3,
                    "flow_control_label_yield_gallons": 42,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 4,
                    "company": "Hillyard"
                },
                {
                    "number": "Signet Neutral Disinfectant",
                    "product_name": "Signet Neutral Disinfectant Cleaner",
                    "epa_reg_no": "10324-141",
                    "color": "Blue",
                    "dilution": "1 to 256",
                    "ounces_per_gallon": 0.5,
                    "rtu_active_ppm": 660,
                    "rtu_ph": 7,
                    "flow_control_label_yield_gallons": 256,
                    "chemical_container_volume": 3.785,
                    "containers_per_case": 1,
                    "company": "Cintas/Diversey"
                },
                {
                    "number": "Diversey Alpha HP Disinfectant",
                    "product_name": "Diversey Diversey HP Multi Surface Disinfectant Cleaner--J-Fill",
                    "epa_reg_no": "70627-62",
                    "color": "Clear",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 664,
                    "rtu_ph": 1.9,
                    "flow_control_label_yield_gallons": 42.25,
                    "chemical_container_volume": 2.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Diversey Alpha HP Disinfectant",
                    "product_name": "Diversey Diversey HP Multi Surface Disinfectant Cleaner--RTD",
                    "epa_reg_no": "70627-62",
                    "color": "Clear",
                    "dilution": "1 to 64",
                    "ounces_per_gallon": 2,
                    "rtu_active_ppm": 664,
                    "rtu_ph": 1.9,
                    "flow_control_label_yield_gallons": 25.35,
                    "chemical_container_volume": 1.5,
                    "containers_per_case": 2,
                    "company": "Diversey"
                },
                {
                    "number": "Spartan HDQ Neutral Disinfectant Cleaner",
                    "product_name": "Spartan HDQ Neutral Disinfectant Cleaner",
                    "epa_reg_no": "10324-155",
                    "color": "Red",
                    "dilution": "1 to 128",
                    "ounces_per_gallon": 1,
                    "rtu_active_ppm": 660,
                    "rtu_ph": 7.5,
                    "flow_control_label_yield_gallons": 153.7,
                    "chemical_container_volume": 4.546,
                    "containers_per_case": 4,
                    "company": "Spartan"
                }
            ]
        };

        this.applicationStrings = {
            // "INPUT_HELP_BURNISH_PER_YEAR": "",
        };
    }
}

export default new Constants();