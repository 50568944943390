import * as React from "react";
import * as Types from "../../lib/Types";

import {Col, Form, Row, Table, InputGroup, Alert} from "react-bootstrap";

import Constants from "../../lib/Constants";
import {AppContext} from "./AppState";
import {PropsWithChildren} from "react";

interface IProps {
    onProductOneChange: (productKey: string) => void,
    onProductOneCaseCostChange: (cost: number) => void,
    onProductTwoChange: (productKey: string) => void,
    onProductTwoCaseCostChange: (cost: number) => void
}

export const ProductSelect: React.FC<IProps> = (props: PropsWithChildren<any>): React.ReactElement => {

    const context: Types.ContextType = React.useContext(AppContext)

    const renderSelectOptions = (): React.ReactElement[] => {
        return _.map(Constants.products.productList, (item: Types.ProductItem, index: number): React.ReactElement => {
            return <option key={`${index}`} value={item.number}>
                {item.product_name}
            </option>;
        });
    };

    const defaultSelectedProductOneNumber:string = context.state.productOne ? context.state.productOne.number : null;
    const defaultSelectedProductOneCaseCost:number = context.state.productOneCaseCost ? context.state.productOneCaseCost : null;
    const defaultSelectedProductTwoNumber:string = context.state.productTwo ? context.state.productTwo.number : null;
    const defaultSelectedProductTwoCaseCost:number = context.state.productTwoCaseCost ? context.state.productTwoCaseCost : null;

    return <div>
        <Row>
            <Col md={8}>

                <Form.Group controlId="productSelectForm.product1">
                    <Form.Label>Product 1</Form.Label>
                    <Form.Control
                        as="select"
                        defaultValue={defaultSelectedProductOneNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            props.onProductOneChange(event.target.value);
                        }}
                    >
                        <option value="">--</option>
                        {renderSelectOptions()}
                    </Form.Control>
                </Form.Group>

            </Col>
            <Col md={4}>

                <Form.Label>Case Cost</Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="number"
                        placeholder="0.00"
                        defaultValue={defaultSelectedProductOneCaseCost}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            props.onProductOneCaseCostChange(parseFloat(event.target.value));
                        }}
                    />
                </InputGroup>

            </Col>
        </Row>

        <Row>
            <Col md={8}>

                <Form.Group controlId="productSelectForm.product2">
                    <Form.Label>Product 2</Form.Label>
                    <Form.Control
                        as="select"
                        defaultValue={defaultSelectedProductTwoNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            props.onProductTwoChange(event.target.value);
                        }}
                    >
                        <option value="">--</option>
                        {renderSelectOptions()}
                    </Form.Control>
                </Form.Group>

            </Col>
            <Col md={4}>

                <Form.Label>Case Cost</Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="number"
                        placeholder="0.00"
                        defaultValue={defaultSelectedProductTwoCaseCost}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            props.onProductTwoCaseCostChange(parseFloat(event.target.value));
                        }}
                    />
                    {/*<Form.Text className="text-muted">*/}
                    {/*    Enter Case Cost*/}
                    {/*</Form.Text>*/}
                </InputGroup>

            </Col>
        </Row>

        <Choose>

            <When condition={context.state.productOne && context.state.productTwo}>

                <Table
                    id={"products-table"}
                    striped={true}
                    bordered={true}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "30%" }}>Product</th>
                        <th>Company</th>
                        <th>Chemical Container Volume (Liters)</th>
                        <th>Active Specification (PPM)</th>
                        <th>Product Label Yield (Gallons)</th>
                        <th>Label Dilution Ratio (oz/Gal)</th>
                    </tr>
                    </thead>
                    <tbody>

                    <If condition={context.state.productOne}>

                        <tr>
                            <td>
                                <strong>{context.state.productOne.product_name}</strong>
                            </td>
                            <td>
                                {context.state.productOne.company}
                            </td>
                            <td>
                                {context.state.productOne.chemical_container_volume}
                            </td>
                            <td>
                                {context.state.productOne.rtu_active_ppm}
                            </td>
                            <td>
                                {context.state.productOne.flow_control_label_yield_gallons}
                            </td>
                            <td>
                                {context.state.productOne.dilution}
                            </td>
                        </tr>

                    </If>

                    <If condition={context.state.productTwo}>

                        <tr>
                            <td>
                                <strong>{context.state.productTwo.product_name}</strong>
                            </td>
                            <td>
                                {context.state.productTwo.company}
                            </td>
                            <td>
                                {context.state.productTwo.chemical_container_volume}
                            </td>
                            <td>
                                {context.state.productTwo.rtu_active_ppm}
                            </td>
                            <td>
                                {context.state.productTwo.flow_control_label_yield_gallons}
                            </td>
                            <td>
                                {context.state.productTwo.dilution}
                            </td>
                        </tr>

                    </If>

                    </tbody>
                </Table>

            </When>
            <Otherwise>

                <Alert variant={"info"}>
                    Select two products to begin.
                </Alert>

            </Otherwise>

        </Choose>

    </div>;
};
