import React, {Component} from "react";
import {FaDownload, FaFilePdf} from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Iframe from "react-iframe";

import * as Types from "../../lib/Types";
import {AppContext} from "./AppState";

import * as Helpers from "../../lib/Helpers";

const ExportPdf: React.FC<Types.NoPropsType> = (props: Types.NoPropsType): React.ReactElement => {

    const {state} = React.useContext(AppContext);

    const [pdfUrl, setPdfUrl] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [isModalShown, setIsModalShown] = React.useState(false)
    const [isPDFShown, setIsPDFShown] = React.useState(false)
    const [isDownloadLinkShown, setIsDownloadLinkShown] = React.useState(false)

    const handleOpenPDF = (): void => {
        setIsPDFShown(true)
    }

    const handleDownloadPDF = (): void => {
        setIsDownloadLinkShown(true)
    }

    const handleClose = (): void => {
        setIsModalShown(false)
    }

    const handleClick = (): void => {

        const apiPath: string = "/api/v1/report/accuracy-promise";
        const dataToSend: object = {
            ...Helpers.allTrialsResultsData(state),
            // chartData: Helpers.getChartInputs(state),
            percentErrorChartData: Helpers.getPercentErrorChartData(state),
            priceFluctuationChartData: Helpers.getPriceFluctuationChartData(state),
            productOneCaseCost: state.productOneCaseCost,
            productTwoCaseCost: state.productTwoCaseCost
        }

        // console.log(dataToSend)

        setIsLoading(true)

        const options: object = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(dataToSend)
        };

        fetch(apiPath, options)
            .then((response: Response): any => response.json())
            .then((json: any): void => {

                setIsLoading(false)
                setIsModalShown(true)
                setIsPDFShown(false)
                setIsDownloadLinkShown(false)
                setPdfUrl(json.pdf_url)

                console.log(json.pdf_url)

            })
            .catch((e: Error): void => {
                console.log("parsing failed", e);
            })
    };

    const isIosDevice = (): boolean => {

        // console.log("isIosDevice");

        // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios

        const deviceStrings: string[] = [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod"
        ];

        // iPad on iOS 13 detection
        return deviceStrings.includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    };

    return (
        <div>

            <Modal
                show={isModalShown}
                className={""}
                dialogClassName={"modal-90w"}
                onHide={handleClose}
            >

                <Modal.Header closeButton>
                    <Modal.Title> Export PDF </Modal.Title>
                </Modal.Header>

                <Choose>
                    <When condition={isPDFShown === false && isDownloadLinkShown === false}>

                        <Modal.Body>

                            <Choose>
                                <When condition={isIosDevice()}>

                                    <a
                                        href={`${pdfUrl}&download=true`}
                                        className={"btn btn-lg btn-outline-secondary btn-block"}
                                        // target={"_blank"}
                                    >
                                        <FaDownload />
                                        &nbsp;
                                        Download
                                    </a>

                                </When>
                                <Otherwise>

                                    <Button
                                        variant="outline-secondary"
                                        block={true}
                                        size={"lg"}
                                        onClick={handleOpenPDF}
                                    >
                                        <FaFilePdf />
                                        &nbsp;
                                        Open PDF
                                    </Button>

                                </Otherwise>
                            </Choose>

                        </Modal.Body>

                    </When>
                    <When condition={isPDFShown}>

                        <Modal.Body>

                            <Iframe
                                url={pdfUrl}
                                width={"100%"}
                                height={"100%"}
                                id={"pdfIframe"}
                                position={"relative"}
                            />

                        </Modal.Body>

                    </When>
                </Choose>

                <Modal.Footer>

                    <Button
                        variant="secondary"
                        block={true}
                        size={"lg"}
                        onClick={handleClose}
                    >
                        Close
                    </Button>

                </Modal.Footer>

            </Modal>

            <Button
                variant="primary"
                disabled={isLoading}
                onClick={!isLoading ? handleClick : null}
            >
                <FaDownload />
                &nbsp;
                {isLoading ? "Loading..." : "Export PDF"}
            </Button>

        </div>
    );
}

export default ExportPdf;