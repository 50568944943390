import * as React from "react";
import * as _ from "lodash";
import * as Types from "../lib/Types";
import {Button, Card, Col, Nav, Row, Tab, Tabs, Alert} from "react-bootstrap";
import logger from "use-reducer-logger";

import {reducer, initialState, initalStateDebug, AppContext} from "./CalculatorApp/AppState";
import {ResultsTable} from "./CalculatorApp/ResultsTable";
import {ProductSelect} from "./CalculatorApp/ProductSelect";
import {TrialInputs} from "./CalculatorApp/TrialInputs";
import {PercentErrorChart} from "./CalculatorApp/Charts/PercentErrorChart";
import {PriceFluctuationChart} from "./CalculatorApp/Charts/PriceFluctuationChart";
import ExportPdf from "./CalculatorApp/ExportPDF";

import {allTrialsResultsData, productForKey} from "../lib/Helpers";

// https://stackoverflow.com/a/51583401
(global as any)._ = _;

const CalculatorApp: React.FC<{ debug: string }> = (props: { debug: string }): React.ReactElement => {

    const [state, dispatch] = React.useReducer(logger(reducer), props.debug ? initalStateDebug : initialState);

    // Event handlers
    const handleProductOneChange = (productKey: string): void => {

        const product: Types.ProductItem = productForKey(productKey);

        dispatch({
            type: "change_product_one",
            payload: product
        });
    };

    const handleProductOneCaseCostChange = (cost: number): void => {

        dispatch({
            type: "change_product_one_case_cost",
            payload: cost
        });
    };

    const handleProductTwoChange = (productKey: string): void => {

        const product: Types.ProductItem = productForKey(productKey);

        dispatch({
            type: "change_product_two",
            payload: product
        });
    };

    const handleProductTwoCaseCostChange = (cost: number): void => {

        dispatch({
            type: "change_product_two_case_cost",
            payload: cost
        });
    };

    const handleOnAddTrial = (): void => {

        dispatch({
            type: "add_trial",
            payload: {}
        });
    };

    // Rendering

    const renderTrialTabs = (): React.ReactElement[] => {

        return _.map(state.trials, (trial: Types.TrialItem, index: number): React.ReactElement => {

            return <Nav.Item
                key={`${trial.uuid}`}
            >
                <Nav.Link
                    eventKey={`${trial.uuid}`}
                >
                    {`Trial ${index + 1} (${trial.location_name})`}
                </Nav.Link>
            </Nav.Item>;
        });
    };

    const renderTrialTabPanes = (): React.ReactElement[] => {

        return _.map(state.trials, (trial: Types.TrialItem, index: number): React.ReactElement => {

            return <Tab.Pane
                key={`${trial.uuid}`}
                eventKey={`${trial.uuid}`}
            >

                <TrialInputs
                    title={`Trial ${index + 1} ( ${trial.location_name} )`}
                    trial={trial}
                    displayIndex={index + 1}
                />

            </Tab.Pane>;
        });
    };

    const shouldRenderChart = (): boolean => {

        if (state.productOne && state.productTwo && state.productOneCaseCost && state.productTwoCaseCost) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <AppContext.Provider value={{state, dispatch}}>
            <div>

                <div className="row">
                    <div className="col-sm-8">

                        <h4 className={"mb-4"}>
                            3M™ Flow Control Accuracy Calculator
                        </h4>

                    </div>
                    <div className="col-sm-4 text-right">

                        <ExportPdf />

                    </div>
                </div>

                <Card
                    className={"mb-4 shadow-sm"}
                    border={"dark"}
                >

                    <Card.Body>

                        <Choose>
                            <When condition={shouldRenderChart()}>

                                <PercentErrorChart />
                                <PriceFluctuationChart />

                            </When>
                            <Otherwise>

                                <div>

                                    <Row className={"mb-4"}>
                                        <Col className={"text-center"}>
                                            <strong>Getting Started</strong>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className={"text-center"}>
                                            <span className={"lead"}>1. Select products to compare</span>
                                        </Col>
                                        <Col className={"text-center"}>
                                            <span className={"lead"}>2. Enter case costs</span>
                                        </Col>
                                        <Col className={"text-center"}>
                                            <span className={"lead"}>3. Enter trial data</span>
                                        </Col>
                                    </Row>

                                </div>

                            </Otherwise>
                        </Choose>

                    </Card.Body>
                </Card>

                <Tabs defaultActiveKey={"product-selection"}>

                    <Tab
                        title={"Product Selection"}
                        eventKey={"product-selection"}
                    >

                        <Card
                            className={"my-4 shadow-sm"}
                        >

                            <Card.Body>

                                <ProductSelect
                                    onProductOneChange={handleProductOneChange}
                                    onProductOneCaseCostChange={handleProductOneCaseCostChange}
                                    onProductTwoChange={handleProductTwoChange}
                                    onProductTwoCaseCostChange={handleProductTwoCaseCostChange}
                                />

                            </Card.Body>
                        </Card>

                    </Tab>

                    <Tab
                        title={"Trials"}
                        eventKey={"trials"}
                    >

                        <Tab.Container
                            defaultActiveKey={state.trials[0].uuid}
                        >
                            <Row className={"my-4"}>
                                <Col sm={3}>

                                    <Nav variant="pills" className="flex-column">
                                        {renderTrialTabs()}
                                    </Nav>

                                    <Button
                                        block
                                        className={"my-2"}
                                        variant="outline-primary"
                                        onClick={(event: React.MouseEvent<HTMLInputElement>): void => {
                                            handleOnAddTrial();
                                        }}
                                    >
                                        Add Trial
                                    </Button>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        {renderTrialTabPanes()}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Tab>

                    <Tab
                        title={"Output"}
                        eventKey={"output"}
                    >

                        <If condition={state.productOne && state.productTwo}>

                            <Card className={"my-4 shadow-sm"}>

                                <Card.Body>

                                    <ResultsTable
                                        results={allTrialsResultsData(state)}
                                    />

                                </Card.Body>

                            </Card>

                        </If>

                    </Tab>

                    <If condition={props.debug}>

                        <Tab
                            title={"DEBUG"}
                            eventKey={"debug"}
                        >

                            <Card className={"my-4 shadow-sm"}>

                                <Card.Body>

                                    DEBUG

                                </Card.Body>

                            </Card>

                        </Tab>

                    </If>

                </Tabs>

            </div>
        </AppContext.Provider>);
};

export default CalculatorApp;