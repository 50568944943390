import * as React from "react";
import * as Types from "../../lib/Types";
import {formatDecimalToString, formatDecimalToCurrencyString} from "../../lib/Helpers";

import {Table, Row, Col} from "react-bootstrap";

interface IProps {
    results: Types.AllTrialsResultsOutputs
}

export const ResultsTable: React.FC<IProps> = (props: IProps): React.ReactElement => {

    return <div>

        <If condition={props.results != null}>

            <Row>
                <Col>

                    <Table
                        className={"results-table"}
                        striped={true}
                        bordered={true}
                    >
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>Average Cost $/RTU Gal</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                {props.results.productOne.product_name}
                            </td>
                            <td>
                                {formatDecimalToCurrencyString(props.results.averageCostProductOne)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {props.results.productTwo.product_name}
                            </td>
                            <td>
                                {formatDecimalToCurrencyString(props.results.averageCostProductTwo)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Potential Savings (%)
                            </td>
                            <td>
                                {formatDecimalToString((props.results.potentialSavings * 100), 2)}%
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                </Col>
                <Col>

                    <Table
                        className={"results-table"}
                        striped={true}
                        bordered={true}
                    >
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>Average % Error</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                {props.results.productOne.product_name}
                            </td>
                            <td>
                                {formatDecimalToString(props.results.averagePercentErrorProductOne, 0)}%
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {props.results.productTwo.product_name}
                            </td>
                            <td>
                                {formatDecimalToString(props.results.averagePercentErrorProductTwo, 0)}%
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Potential Increased Accuracy
                            </td>
                            <td>
                                {formatDecimalToString(props.results.potentialIncreasedAccuracy, 0)}%
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                </Col>
            </Row>

            <Table
                className={"results-table"}
                striped={true}
                bordered={true}
            >
                <thead>
                <tr>
                    <th style={{width: "40%"}}>Product</th>
                    <th colSpan={99}>Percent Error</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {props.results.productOne.product_name}
                    </td>
                    {_.map(props.results.trials, (trial: Types.TrialItem): React.ReactComponentElement<any> => {
                        return <td key={trial.uuid}>
                            {formatDecimalToString(trial.product_one_percent_error_from_mfg_target, 0)}%
                        </td>
                    })}
                </tr>
                <tr>
                    <td>
                        {props.results.productTwo.product_name}
                    </td>
                    {_.map(props.results.trials, (trial: Types.TrialItem): React.ReactComponentElement<any> => {
                        return <td key={trial.uuid}>
                            {formatDecimalToString(trial.product_two_percent_error_from_mfg_target, 0)}%
                        </td>
                    })}
                </tr>

                </tbody>

            </Table>

            <Table
                striped={true}
                bordered={true}
                className={"results-table"}
            >
                <thead>
                <tr>
                    <th style={{width: "40%"}}>Product</th>
                    <th colSpan={99}>Cost/ RTU Gallon</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {props.results.productOne.product_name}
                    </td>
                    {_.map(props.results.trials, (trial: Types.TrialItem): React.ReactComponentElement<any> => {
                        return <td key={trial.uuid}>
                            {formatDecimalToCurrencyString(trial.product_one_realized_cost_rtu_gallon)}
                        </td>
                    })}
                </tr>
                <tr>
                    <td>
                        {props.results.productTwo.product_name}
                    </td>
                    {_.map(props.results.trials, (trial: Types.TrialItem): React.ReactComponentElement<any> => {
                        return <td key={trial.uuid}>
                            {formatDecimalToCurrencyString(trial.product_two_realized_cost_rtu_gallon)}
                        </td>
                    })}
                </tr>

                </tbody>

            </Table>

        </If>

    </div>;
};